/*
 * @Author: your name
 * @Date: 2021-03-06 10:03:08
 * @LastEditTime: 2022-02-25 16:18:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\store\modules\user.js
 */
import { getItem, removeItem, setItem } from '@/utils/cache'
import { userModel } from '@/api/user'

const TOKEN_KEY = 'token'
const REFRESH_TOKEN_KEY = 'refreshToken'
const NAME_KEY = 'name'
const PHONE_NUM_KEY = 'phoneNum'
const SEX_KEY = 'sex'
const AVATAR_KEY ='avatar'
const WX_CODE ='code'
export const state={
  user:{
    name: '',
    phoneNum: getItem(PHONE_NUM_KEY, ''),
    sex: getItem(SEX_KEY, ''),
    avatar:getItem(AVATAR_KEY,'')
  },
  code: getItem(WX_CODE,''),
  token: getItem(TOKEN_KEY, ''),
  refreshToken: getItem(REFRESH_TOKEN_KEY, '')
}
export const getters={
  user(state) {
    return state.user
  }
}
export const mutations= {

  setAvatar(state, avatar) {
    state.user.avatar = avatar
    setItem(AVATAR_KEY, avatar)
  },
  setToken(state, token) {
    state.token = token
    setItem(TOKEN_KEY, token)
  },
  setRefreshToken(state, token) {
    state.refreshToken = token
    setItem(REFRESH_TOKEN_KEY, token)
  },
  setWxCode(state, code) {
    state.code = code
    setItem(REFRESH_TOKEN_KEY, code)
  },
  setName(state, name) {
    state.user.name = name
    setItem(REFRESH_TOKEN_KEY, name)
  },
  setPhoneNum(state, phoneNum) {
    state.user.phoneNum = phoneNum
    setItem(PHONE_NUM_KEY, phoneNum)
  },
  setSex(state, sex) {
    state.user.sex = sex
    setItem(SEX_KEY, sex)
  },
  clearData(state) {
    [TOKEN_KEY, REFRESH_TOKEN_KEY, NAME_KEY, SEX_KEY].forEach(key => {
      state[key] = ''
      removeItem(key)
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions: {
    refreshToken({ commit }, params) {
      return new Promise((resolve) => {
        userModel.refreshToken(params).then(res => {
          if (res.data) {
            const { token, refreshToken } = res.data
            commit('setToken', token)
            commit('setRefreshToken', refreshToken)
            return resolve(res)
          }

          // reject()
        }).catch(e => {
          console.log(e)

        })
      })
    },
    logout({ commit }, params) {
      return new Promise((resolve) => {
        userModel.logout(params).then(() => {
          commit('clearData')
          resolve()
        })
      })
    }
  }


}
