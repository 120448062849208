/*
 * @Author: your name
 * @Date: 2021-03-02 12:09:00
 * @LastEditTime: 2021-03-05 15:32:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\utils\cache.js
 */
/**
 * 将数据存入localstorage中
 * @param key 在localstorage中保存的key
 * @param value 数据
 */
import { isDef } from './index'

export function setItem(key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.log('save storage error', e)
  }
}

/**
 * 从localstorage中获取数据
 * @param key localstorage中对应的key值
 * @param def 取不到值时的返回的默认值
 * @returns {string | null}
 */
export function getItem(key, def = null) {
  let value = window.localStorage.getItem(key)
  // 将能够转成对象的json字符串转成对象
  try {
    value = JSON.parse(value)
  } catch (e) {
    console.log('json transform failed', e)
  }

  return isDef(value) ? value : def
}

export function removeItem(key) {
  window.localStorage.removeItem(key)
}
/**
 * 将1-9之间的数字格式化成01-09
 * @param value
 * @returns {string}
 */
export function appendZero(value) {
  value = value + ''
  return `00${ value }`.substr(value.length)
}

/**
 * 时间格式化
 * @param time 时间字符串或者时间
 * @param fmt 格式化的
 * @returns {string}
 */
export function formatTime(time, fmt = 'yyyy-MM-dd') {
  const date = (typeof time === 'string' || typeof time === 'number') ? new Date(time) : time
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    S: date.getMilliseconds()
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : appendZero(o[k]))
    }
  }
  return fmt
}
export function  getBirth(idCard) {
  var birthday = ""
  if(idCard != null && idCard != ""){
    if(idCard.length == 15){
      birthday = "19"+idCard.slice(6,12)
    } else if(idCard.length == 18){
      birthday = idCard.slice(6,14)
    }
    birthday = birthday.replace(/(.{4})(.{2})/,"$1-$2-")
    //通过正则表达式来指定输出格式为:1990-01-01
  }
  return birthday
}

