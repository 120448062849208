<template>
  <div>
    <div class="message">
      <img class="img" src="../../assets/img/nofound.png" alt="">
      <p v-if="title" class="call-word">
        暂无患者发起{{ title }}申请！
      </p>
      <p v-if="noDataText" class="call-word">
        {{ noDataText }}
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    props:{
      title:{
        type:String,
        default:''
      },
      noDataText:{
        type:String,
        default:'暂无数据'
      }
    }
  }
</script>
<style scoped lang="less">
@import "../../assets/styles/variable.less";
        .message{
            // background: #ffffff;
            width:100%;
            height: 569px;
            border-radius: 20px;
            display:flex;
            align-items: center;
            flex-direction:column;
            justify-content:center;

            .img{
                width:150px;
                height: 153px;
            }
            .call-word{
                margin-top:53px;
                font-size: @font-size-28;
                color: @middle-font-color;
            }
        }
</style>
