/*
 * @Author: your name
 * @Date: 2021-03-02 12:09:00
 * @LastEditTime: 2024-02-21 14:33:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lsz-user-mp\src\router\permission.js
 */
import router from './index'
import store from '../store'
import {SET_HEADER_CONFIG} from '../store/mutation-types'

const WHITE_LIST = ['/login', '/register', '/', '/user', '/hospitalRoute', '/hospitalIntroduction', '/introduce/privacy', '/introduce/service','/payResult','prePayResult']
const WHITE_ROUTE_NAME_LIST = ['videoChat']

router.beforeEach((to, from, next) => {
  const metaInfo = { showBackBtn:true,bgClass:'white-bg', ...to.meta }
  store.commit(`header/${SET_HEADER_CONFIG}`, metaInfo)

  if (WHITE_LIST.includes(to.path) || WHITE_ROUTE_NAME_LIST.includes(to.name)) {
    return next()
  }

  if (!store.getters.token) {
    next('/login')
  } else {
    next()
  }
})
