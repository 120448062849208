/*
 * @Author: your name
 * @Date: 2021-03-09 14:23:12
 * @LastEditTime: 2021-03-13 17:18:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\store\getters.js
 */
const getters = {
  token: state => state.user.token,
  refreshToken: state => state.user.refreshToken,
  user: state => state.user.user,
  phoneNum: state => state.user.user.phoneNum
}
export default getters
