<!--
 * @Author: your name
 * @Date: 2021-03-10 17:54:57
 * @LastEditTime: 2021-03-15 14:53:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\patient\patientSelect.vue
-->
<template>
  <div>
    <van-cell
      title="就诊人"
      is-link
      :value="patient"
      size="large"
      class="cellStyle"
      @click="Picker = true"
    />
    <van-popup v-model="Picker" position="bottom">
      <van-picker show-toolbar :columns="list" @confirm="confirm" @cancel="Picker = false" />
    </van-popup>
  </div>
</template>
<script>
import { userModel } from '@/api/user'
import{setItem} from '../../utils/cache'
export default {
  name:'PatientSelect',
  data(){
    return{
      list:[{text:'全部',id:'',zjh:'',zjlx:'',userName:''}],
      Picker:false,
      patient:{text:'全部',id:'',zjh:'',zjlx:'',userName:''}

    }
  },
  mounted(){
    userModel.patientList().then(res=>{
        const list = res.data.patLists||[]
        let pat = list.find((item)=>{return item.isdefault=='1'})||{}
        // console.log(list,"list")
        //this.patient ={text:'全部',id:'',zjh:'',zjlx:'',userName:''}
       setItem('PatentId',pat.id)
        this.$emit('onConfirm',this.patient)
       // let arr=[]
        //就诊人格式转换
        for(let i=0; i<list.length; i++){
          let obj={}
          obj.text = list[i].patxm,
          obj.id = list[i].id,
          obj.zjh = list[i].zjh
          obj.zjlx=list[i].zjlx
          obj.userName=list[i].userName?list[i].userName:''
          this.list.push(obj)
        }
        // console.log( this.list,"最终结果")

        //this.list = arr
    })
  },
  methods:{
    confirm(value){
      // console.log(value,"当前选中的就诊人")
      setItem('PatentId',value.id)
      this.patient=value.text
      this.Picker=false
      this.$emit('onConfirm',value)
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
.cellStyle {
  background: @theme-color;
}

.van-cell__title,
.van-cell__value,
.van-cell__right-icon {
  color: #ffffff;
}
</style>
