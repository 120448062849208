/*
 * @Author: your name
 * @Date: 2021-03-02 18:39:24
 * @LastEditTime: 2021-03-09 18:03:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\store\header.js
 */

import * as types from '../mutation-types'

const defaultConfig = {
  // 标题
  title: '',
  // 背景class名称，默认为white-bg，白色背景; theme-bg: 蓝色背景
  bgClass: 'white-bg',
  // 模式：logo: 显示logo；text：显示文字
  mode: 'text',
  // 是否显示返回按钮
  showBackBtn: true,
  // 是否需要自定义处理返回事件，只在showBackBtn为true时才生效
  customBackEvent: false,
  // 是否显示右边按钮
  showRightBtn: false,
  // 右边按钮文字
  rightBtnText: '',
  // 右边按钮高亮
  rightBtnActive: false,
  // 是否只在右侧按钮是active时派发事件
  emitEventByActive: true
}

export const state = {
  headerCompConfig: { ...defaultConfig }
}
export const getters = {
  headerCompConfig(state) {
    return state.headerCompConfig
  }
}
export const mutations = {
  [types.SET_HEADER_CONFIG](state, config) {
    state.headerCompConfig = { ...defaultConfig, ...config }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations
}

