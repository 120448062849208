/*
 * @Author: your name
 * @Date: 2021-03-06 16:33:46
 * @LastEditTime: 2021-03-11 09:36:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\api\constant.js
 */
import { Http } from '@/utils/http'
import {combineUrl} from '../utils/index'
const URL_PREFIX = '/user'
export class ConstantModel extends Http {
  getAddress(){
    return this.post(combineUrl(URL_PREFIX, '/constants/getAllConstants'))
  }
  typeOutpat(params){
    return this.post(combineUrl(URL_PREFIX, '/constants/list'), params,{isFormData:true})
  }
  upImage(file){
    return this.post('/fdfs/upload',{file}, {isFormData:true})
  }
  //查询疾病
  consultill(params) {
    return this.get(combineUrl(URL_PREFIX,'/icd10/getList'),params)
  }
  //查询就诊人疾病
  patientIll(params){
    return this.post(combineUrl(URL_PREFIX,'/appointment/patientvisitrecords'),params,{isFormData:true})
  }
  //价格公示
  getPrice(params){
    return this.post('/basic/medicines/list',params)
  }
  getServeList(params){
    return this.post('/user/expenses/list',params)
  }
}
export const constantModel = new ConstantModel()
