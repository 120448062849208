/*
 * @Author: your name
 * @Date: 2021-03-02 13:23:16
 * @LastEditTime: 2022-02-25 14:28:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import {appointmentRoutes} from './appointment'
import {patientRoutes} from './patient'
import {chatRoutes}from './chat'
import { userRoutes } from './user'

Vue.use(VueRouter)
const originalPush=VueRouter.prototype.push
VueRouter.prototype.push=function push (location){
  return originalPush.call(this,location).catch(err=>err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      showBackBtn: false,
      pageBackground: 'grey-bg'

    },
    component: () => import('../views/home/home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: () => import('../views/register/register.vue')
  // },
  {
    path: '/introduce/privacy',
    name: 'privacy',
    meta: {
      title: '隐私保护政策'
    },
    component: () => import('../views/introduce/privacy/privacy.vue')
  },
  {
    path: '/introduce/service',
    name: 'service',
    meta: {
      title: '用户协议'
    },
    component: () => import('../views/introduce/service/service.vue')
  },
  {
    path: '/pricePublicity',
    name: 'PricePublicity',
    meta: {
      title:'价格公示'
    },
    component: () => import('../views/price/pricePublicity.vue')
  },

  {
    path: '/hospitalIntroduction',
    name: 'HospitalIntroduction',
    meta: {
      title:'医院介绍'
    },
    component: () => import('../views/static/hospitalIntroduction.vue')
  },
  {
    path: '/hospitalRoute',
    name:'HospitalRoute',
    meta: {
      title:'来院路线'
    },
    component: () => import('../views/static/hospitalRoute.vue')
  },
  {
    path: '/invoice/:orderNumber',
    name: 'Invoice',
    meta: {
      title: '开票'
    },
    component: () => import('../views/invoice/Invoice')
  },
  ...appointmentRoutes,
  ...patientRoutes,
  ...chatRoutes,
  ...userRoutes
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
