/*
 * @Author: your name
 * @Date: 2021-03-04 17:06:55
 * @LastEditTime: 2021-03-09 09:42:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\router\patient.js
 */
export const patientRoutes = [
  {
    path: '/patient',
    name: 'patient',
    meta: {
      title:'就诊人管理',
      customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/patient/index.vue')
  },
  {
    path: '/patient/:id',
    name: 'patientFrom',
    meta: {
      title:'修改就诊人',
      customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/patient/from.vue')
  }

]
